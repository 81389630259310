/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
;
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';

@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

@import '../node_modules/@syncfusion/ej2-angular-diagrams/styles/material.css';
@import "assets/theme/syncfusion-material.css";
// Import for the new Componeny Library component styles
@import "../node_modules/@theevolvedgroup/component-library/styles/styles.less";

html,
body {
    height: 100%;
}

body {
    margin: 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #2093cc;
}

.mat-checkbox-ripple {
    height: 0px !important;
}

button.mat-button {
    font-size: 14px;
    font-weight: 500;
}

button.mat-button[color="primary"] {
    background-color: #2093cc;
    color: #fff !important;
}