.e-icons {
  font-family: "e-icons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

.e-diagram-menu .e-bringforward::before {
  content: "\e574";
}
.e-diagram-menu .e-bringfront::before {
  content: "\e575";
}
.e-diagram-menu .e-sendback::before {
  content: "\e576";
}
.e-diagram-menu .e-sendbackward::before {
  content: "\e577";
}
.e-diagram-menu .e-delete::before {
  content: "\e578";
}
.e-diagram-menu .e-ungroup::before {
  content: "\e579";
}
.e-diagram-menu .e-group::before {
  content: "\e57a";
}
.e-diagram-menu .e-undo::before {
  content: "\e57c";
}
.e-diagram-menu .e-cut::before {
  content: "\e57d";
}
.e-diagram-menu .e-save::before {
  content: "\e57e";
}
.e-diagram-menu .e-paste::before {
  content: "\e57f";
}
.e-diagram-menu .e-copy::before {
  content: "\e580";
}
.e-diagram-menu .e-redo::before {
  content: "\e581";
}
.e-diagram-menu .e-zoomin::before {
  content: "\e582";
}
.e-diagram-menu .e-italic::before {
  content: "\e583";
}
.e-diagram-menu .e-close::before {
  content: "\e584";
}
.e-diagram-menu .e-pan::before {
  content: "\e585";
}
.e-diagram-menu .e-bold::before {
  content: "\e586";
}
.e-diagram-menu .e-underline::before {
  content: "\e587";
}
.e-diagram-menu .e-zoomout::before {
  content: "\e588";
}
.e-diagram-menu .e-export::before {
  content: "\e589";
}
.e-diagram-menu .e-print::before {
  content: "\e58a";
}
.e-diagram-menu .e-order::before {
  content: "\e58b";
}

.e-symbolpalette .e-clear-searchtext::before {
  content: "\e58c";
}

.e-icons {
  font-family: "e-icons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

.e-diagram-menu .e-bringforward::before {
  content: "\e5a1";
}
.e-diagram-menu .e-bringfront::before {
  content: "\e5a2";
}
.e-diagram-menu .e-sendback::before {
  content: "\e5a3";
}
.e-diagram-menu .e-sendbackward::before {
  content: "\e5a4";
}
.e-diagram-menu .e-delete::before {
  content: "\e94a";
}
.e-diagram-menu .e-ungroup::before {
  content: "\e5a6";
}
.e-diagram-menu .e-group::before {
  content: "\e5a7";
}
.e-diagram-menu .e-undo::before {
  content: "\e341";
}
.e-diagram-menu .e-cut::before {
  content: "\e33b";
}
.e-diagram-menu .e-save::before {
  content: "\e735";
}
.e-diagram-menu .e-paste::before {
  content: "\e355";
}
.e-diagram-menu .e-copy::before {
  content: "\e33d";
}
.e-diagram-menu .e-redo::before {
  content: "\e354";
}
.e-diagram-menu .e-zoomin::before {
  content: "\e349";
}
.e-diagram-menu .e-italic::before {
  content: "\e35a";
}
.e-diagram-menu .e-close::before {
  content: "\ec0f";
}
.e-diagram-menu .e-pan::before {
  content: "\ec1a";
}
.e-diagram-menu .e-bold::before {
  content: "\e339";
}
.e-diagram-menu .e-underline::before {
  content: "\e343";
}
.e-diagram-menu .e-zoomout::before {
  content: "\e351";
}
.e-diagram-menu .e-export::before {
  content: "\e711";
}
.e-diagram-menu .e-print::before {
  content: "\e34b";
}
.e-diagram-menu .e-order::before {
  content: "\e4a4";
}

.e-symbolpalette .e-clear-searchtext::before {
  content: "\ec0f";
}

.e-diagram {
  background-color: #fff;
  display: block;
}

.e-symbolpalette {
  display: block;
}

.e-overview {
  display: block;
}

.e-content-placeholder.e-diagram.e-placeholder-diagram {
  background-size: 100% 100%;
  max-height: 500px;
  max-width: 500px;
}

.e-content-placeholder.e-symbolpalette.e-placeholder-symbolpalette {
  background-size: 100% 100%;
  max-height: 200px;
  max-width: 200px;
}

.e-diagram-endpoint-handle {
  fill: #fff;
  stroke: #e3165b;
}

.e-diagram-endpoint-handle.e-connected {
  fill: #e3165b;
  stroke: #fff;
}

.e-diagram-endpoint-handle.e-disabled {
  fill: #bdbdbd;
  opacity: 1;
  stroke: #fff;
}

.e-diagram-bezier-control-handle.e-disabled {
  fill: #bdbdbd;
  opacity: 1;
  stroke: #fff;
}

/* stylelint-disable */
.e-diagram-bezier-handle.e-source.e-disabled {
  opacity: 1 !important;
}

.e-diagram-bezier-handle.e-target.e-disabled {
  opacity: 1 !important;
}

.e-diagram-bezier-control-handle {
  fill: #fff;
  stroke: #e3165b;
}

.e-diagram-bezier-segment-handle {
  fill: #fff;
  stroke: #e3165b;
}

.e-symbolpalette .e-acrdn-content {
  background-color: #fff !important;
}

/* stylelint-enable */
.e-diagram-bezier-control-line {
  stroke: #e3165b;
}

.e-diagram-resize-handle {
  fill: #e3165b;
  stroke: #fff;
  stroke: #fff;
}

.e-diagram-helper {
  stroke: #e3165b;
}

.e-diagram-resize-handle.e-disabled {
  fill: #bdbdbd;
  opacity: 1;
  stroke: #fff;
}

.e-diagram-rotate-handle {
  fill: #e3165b;
  stroke: #e3165b;
}

.e-diagram-selector {
  stroke: #e3165b;
}

.e-diagram-selector.e-disabled {
  opacity: 1;
  stroke: #bdbdbd;
}

.e-diagram-selector.e-thick-border {
  stroke: #3f51b5;
  stroke-width: 2;
}

.e-diagram-selector.e-thick-border.e-diagram-lane {
  stroke: #3f51b5;
  stroke-width: 2;
}

.e-diagram-highlighter {
  stroke: #e3165b;
  stroke-width: 2;
}

.e-diagram-selected-region {
  stroke: #e3165b;
}

.e-diagram-pivot-line {
  stroke: #e3165b;
}

.e-symbolpalette .e-symbol-hover:hover {
  background: #eee;
  border-radius: 4px;
}

.e-symbolpalette .e-symbol-selected {
  background: #bdbdbd;
  border-radius: 4px;
}

.e-diagram-tooltip {
  pointer-events: none;
}

.e-diagram .e-ruler {
  background: #f5f5f5;
  color: #f5f5f5;
  font-size: 10px;
}

.e-diagram .e-ruler-overlap {
  background: #f5f5f5;
}

.e-diagram .e-d-ruler-marker {
  stroke: #e3165b;
}

.e-diagram .e-diagram-text-edit {
  background: #fff;
  border-color: #e3165b;
  border-style: dashed;
  border-width: 1px;
  box-sizing: content-box;
  color: #000;
  min-width: 50px;
}

.e-diagram-text-edit::selection {
  background: #e3165b;
  color: #fff;
}

.e-ruler-tick-label {
  fill: #616161;
}

.e-ruler-tick {
  stroke: #bdbdbd;
}

.e-menu-item e-blankicon {
  padding-left: 28px;
}

.e-diagram-selection-indicator {
  stroke: #e3165b;
}

.e-diagram-selection-line {
  stroke: #e3165b;
}

/* stylelint-disable */
.e-diagram-rotate {
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsSAAALEgHS3X78AAAA60lEQVQ4jZ1SwQ2DMBAzlfLJI2KEjJAVukG7ARsxQkfoKHQDugHdgOoqh14oCaGWIpSTudzZRg2MdYOxrq0il2Csm411o7Eu5GinTN0D6AGMAGZVux9p1LGBrHIF0LD+4l3ql70thDABSFZYaeTJ6UqNWhL3ELhyyhUR98TcwI06Lk1aNonO1NocqGWy/6zOcGCq6OjiSCw22/wUfEzL8ND2P9duFXBmHMDvWVN7CliFkkGe69VM5RmVLDqG7ZMPOroWP5BTle5WjR6dkQayutyLqU6gsiU/ypHMiI41yf+CE01qov+R0egXAN5x6jng51I3yAAAAABJRU5ErkJggg==), auto !important;
}

.e-symbolpalette-hidden {
  display: none !important;
}

.e-symbolpalette-search-hidden {
  display: none !important;
}

.e-diagramTooltip-content {
  width: auto !important;
  height: auto !important;
}

/* stylelint-enable */